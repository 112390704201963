import React from 'react'
import clsx from 'clsx'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'

import logo from "./../images/landing-logo.svg"

const useStyles = makeStyles((theme: Theme) => createStyles({}));

type LogoProps = {} & typeof defaultProps

const defaultProps = {}

const Logo: React.FunctionComponent<LogoProps> = () => {
  const classes = useStyles();

  return (
    <header>
      <Typography component="span" variant="h1">
        <img src={logo} alt="Landing.com" />
      </Typography>
    </header>
  )
}

export default Logo
