import React from 'react';
import { Link } from 'gatsby'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'

import Copyright from './Copyright'
import Logo from './Logo'

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
    },
    padding: theme.spacing(5),
  },
  logo: {
    [theme.breakpoints.up('md')]: {
      top: '0',
      left: '0',
      position: 'absolute',
      width: '50px',
      marginTop: theme.spacing(1),
      marginRight: 0,
      marginBottom: 0,
      marginLeft: -1 * theme.spacing(15),
    },
    marginTop: 0,
    marginRight: 0,
    marginBottom: theme.spacing(3),
    marginLeft: 0,
    textTransform: 'uppercase',
    width: '50px',
  },
  container: {
    [theme.breakpoints.up('md')]: {},
    position: 'relative',
  },
}));

type SmallWindowProps = { children: React.ReactNode } & typeof defaultProps

const defaultProps = {}

const SmallWindow: React.FunctionComponent<SmallWindowProps> = ({
  children
}: SmallWindowProps) => {
  const classes = useStyles();

  return (
    <Container
      component="main"
      maxWidth="sm"
      className={classes.root}
    >
      <div className={classes.container}>
        <div className={classes.logo}>
          <Logo />
        </div>
        {children}
      </div>
    </Container>
  );
}

export default SmallWindow
